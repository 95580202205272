import React from "react"
import Layout from "../template/index"
import SEO from "../utils/seo"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import SiteLink from "../bits/siteLink"

const NotFoundTitle = styled.h5`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
`

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "not_found.png" }) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="404: Not found" />
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Img fixed={data.file.childImageSharp.fixed} />
        <NotFoundTitle>
          Oops...can't find what you're looking for !!
        </NotFoundTitle>
        <p>
          If you think there's a problem please
          <SiteLink to="/#contact">
            {" "}
            <b>report us</b>
          </SiteLink>{" "}
        </p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
